exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-casestudies-js": () => import("./../../../src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dignio-prevent-js": () => import("./../../../src/pages/dignio-prevent.js" /* webpackChunkName: "component---src-pages-dignio-prevent-js" */),
  "component---src-pages-digniocare-js": () => import("./../../../src/pages/digniocare.js" /* webpackChunkName: "component---src-pages-digniocare-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-helsetjenester-js": () => import("./../../../src/pages/helsetjenester.js" /* webpackChunkName: "component---src-pages-helsetjenester-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issue-collector-2813-j-9-js": () => import("./../../../src/pages/issue-collector-2813j9.js" /* webpackChunkName: "component---src-pages-issue-collector-2813-j-9-js" */),
  "component---src-pages-karie-js": () => import("./../../../src/pages/karie.js" /* webpackChunkName: "component---src-pages-karie-js" */),
  "component---src-pages-medication-js": () => import("./../../../src/pages/medication.js" /* webpackChunkName: "component---src-pages-medication-js" */),
  "component---src-pages-medido-js": () => import("./../../../src/pages/medido.js" /* webpackChunkName: "component---src-pages-medido-js" */),
  "component---src-pages-mydignio-js": () => import("./../../../src/pages/mydignio.js" /* webpackChunkName: "component---src-pages-mydignio-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-pilly-js": () => import("./../../../src/pages/pilly.js" /* webpackChunkName: "component---src-pages-pilly-js" */),
  "component---src-pages-press-releases-js": () => import("./../../../src/pages/press-releases.js" /* webpackChunkName: "component---src-pages-press-releases-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-ressurser-js": () => import("./../../../src/pages/ressurser.js" /* webpackChunkName: "component---src-pages-ressurser-js" */),
  "component---src-pages-solution-js": () => import("./../../../src/pages/solution.js" /* webpackChunkName: "component---src-pages-solution-js" */),
  "component---src-pages-sykehus-js": () => import("./../../../src/pages/sykehus.js" /* webpackChunkName: "component---src-pages-sykehus-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-usecases-js": () => import("./../../../src/pages/usecases.js" /* webpackChunkName: "component---src-pages-usecases-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-feature-js": () => import("./../../../src/templates/feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-usecase-js": () => import("./../../../src/templates/usecase.js" /* webpackChunkName: "component---src-templates-usecase-js" */)
}

